import React, { Component } from "react";
import "./App.css";
import MapChart from "./components/MapChart";
import Languages from "./components/Languages";
import Passport from "./components/Passport";
import ResultSentence from "./components/ResultSentence";
import SubscribeForm from "./components/SubscribeForm";
import {
  longTermCountries,
  longTermButCountries,
  mediumTermCountries,
  mediumTermButCountries,
} from "./data/countries";
import { languageToCountries, passportToCountries } from "./data/helpers";
import cityMarkers from "./data/cityMarkers";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      longTermCountries,
      longTermButCountries,
      mediumTermCountries,
      mediumTermButCountries,
      spokenLanguages: [],
      passports: [],
    };
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.handlePassportChange = this.handlePassportChange.bind(this);
    this.state.personalCountriesRecommendation = this.calculateCountriesRecommendation(
      this.state.spokenLanguages,
      this.state.passports
    );
    this.state.personalCitiesRecommendation = this.calculateCitiesRecommendation(
      this.state.personalCountriesRecommendation
    );
  }

  handleLanguageChange = (event) => {
    const name = event.target.name;
    this.setState((prevState) => {
      let newSpokenLanguages = [];
      let newCountriesRecommendation = [];
      if (!prevState.spokenLanguages.includes(name)) {
        newSpokenLanguages = prevState.spokenLanguages.concat(name);
      } else {
        newSpokenLanguages = prevState.spokenLanguages.filter(
          (item) => item !== name
        );
      }
      newCountriesRecommendation = this.calculateCountriesRecommendation(
        newSpokenLanguages,
        prevState.passports
      );
      return {
        spokenLanguages: newSpokenLanguages,
        personalCountriesRecommendation: newCountriesRecommendation,
        personalCitiesRecommendation: this.calculateCitiesRecommendation(
          newCountriesRecommendation
        ),
      };
    });
  };

  handlePassportChange = (event) => {
    const name = event.target.name;
    this.setState((prevState) => {
      let newPassports = [];
      let newCountriesRecommendation = [];
      if (!prevState.passports.includes(name)) {
        newPassports = prevState.passports.concat(name);
      } else {
        newPassports = prevState.passports.filter((item) => item !== name);
      }
      newCountriesRecommendation = this.calculateCountriesRecommendation(
        prevState.spokenLanguages,
        newPassports
      );
      return {
        passports: newPassports,
        personalCountriesRecommendation: newCountriesRecommendation,
        personalCitiesRecommendation: this.calculateCitiesRecommendation(
          newCountriesRecommendation
        ),
      };
    });
  };

  calculateCountriesRecommendation = (spokenLanguages, passports) => {
    let scorecard = {};
    this.state.longTermCountries.forEach((country) => (scorecard[country] = 5));
    this.state.longTermButCountries.forEach(
      (country) => (scorecard[country] = 4)
    );
    // gap of 2 so medium term options can never score higher than long term options
    this.state.mediumTermCountries.forEach(
      (country) => (scorecard[country] = 2)
    );
    this.state.mediumTermButCountries.forEach(
      (country) => (scorecard[country] = 1)
    );
    
    // language gives +1, but not multiple times for same country
    const countriesLang = [];
    for (let language of spokenLanguages) {
      for (let country of languageToCountries[language]) {
        if (!countriesLang.includes(country)) {
          countriesLang.push(country);
        }
      }
    }
    for (let country of countriesLang) {
      scorecard[country] += 1;
    }

    // passport gives + 1, but not multiple times for same country
    const countriesPass = [];
    for (let passport of passports) {
      for (let country of passportToCountries[passport]) {
        if (!countriesPass.includes(country)) {
          countriesPass.push(country);
        }
      }
    }
    for (let country of countriesPass) {
      scorecard[country] += 1;
    }

    let result = [];
    // best end score can be 5, 6 or 7
    for (let key in scorecard) {
      if (scorecard[key] > 4) {
        result.push(key)
      }
    }
    return result
    // old code that would stop after finding any results
    // for (let i = 7; i > 4; i--) {
    //   for (let key in scorecard) {
    //     if (scorecard[key] === i) {
    //       result.push(key);
    //     }
    //   }
    //   if (result.length > 0) {
    //     return result;
    //   }
    // }
  };

  calculateCitiesRecommendation = (personalCountriesRecommendation) => {
    let result = [];
    cityMarkers.map(({ name, showName, country }) => {
      if (showName && personalCountriesRecommendation.includes(country)) {
        result.push(name);
      }
      return null;
    });
    return result;
  };

  render() {
    return (
      <div className="container-xl">
        <div className="App">
          <div className="row">
            <div className="col-xs-12">
              <MapChart
                blue={this.state.longTermCountries}
                purple={this.state.longTermButCountries}
                yellow={this.state.mediumTermCountries}
                red={this.state.mediumTermButCountries}
                marked={this.state.personalCountriesRecommendation}
                cities={this.state.personalCitiesRecommendation}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-3">
              <ResultSentence
                spokenLanguages={this.state.spokenLanguages}
                passports={this.state.passports}
                personalCountriesRecommendation={
                  this.state.personalCountriesRecommendation
                }
                personalCitiesRecommendation={
                  this.state.personalCitiesRecommendation
                }
              />
              <SubscribeForm />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 mb-3">
              <Passport
                checked={this.state.passports}
                handleChange={this.handlePassportChange}
              />
            </div>
            <div className="col-xs-12 col-sm-6 mb-3">
              <Languages
                checked={this.state.spokenLanguages}
                handleChange={this.handleLanguageChange}
              />
            </div>
          </div>

          <div className="row footer mt-4">
            <ul className="small text-center list-inline mb-0 col-sm-12">
              <li className="list-inline-item">
                <a href="https://nomadlist.com/">Nomad List</a>
              </li>
              <li className="list-inline-item">
                <a href="https://teleport.org/">Teleport Cities</a>
              </li>
            </ul>
            <ul className="small text-center list-inline mb-0 col-sm-12">
              <li className="list-inline-item">
                <a href="https://www.populationpyramid.net/">
                  Population Pyramid
                </a>
              </li>
              <li className="list-inline-item">
                <a href="https://zeihan.com/">Zeihan on Geopolitics</a>
              </li>
              <li className="list-inline-item">
                <a href="https://data.worldbank.org/">World Bank Open Data</a>
              </li>
              <li className="list-inline-item">
                <a href="https://www.cia.gov/library/publications/the-world-factbook/">
                  CIA Factbook
                </a>
              </li>
              <li className="list-inline-item">
                <a href="https://www.transparency.org/">
                  Transparency International
                </a>
              </li>
            </ul>

            <ul className="small text-center list-inline mb-0 col-sm-12">
              <li className="list-inline-item">
                <a href="https://en.wikipedia.org/wiki/Schengen_Area">
                  EU Schengen Area 1995 Open Border Agreement
                </a>
              </li>
              <li className="list-inline-item">
                <a href="https://en.wikipedia.org/wiki/Mercosur">
                  MERCOSUR 2002/2009 Residence Agreement
                </a>
              </li>
              <li className="list-inline-item">
                <a href="https://en.wikipedia.org/wiki/Trans-Tasman_Travel_Arrangement">
                  Trans-Tasman Travel Arrangement 1973
                </a>
              </li>
            </ul>

            <p className="text-center small pt-3 col-sm-12">
              Copyright &copy; {new Date().getFullYear()}, Tristan Kraus. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
