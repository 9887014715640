const longTermCountries = [
  "AU", // Australia
  "BE", // Belgium
  "CA", // Canada
  "CL", // Chile
  "DK", // Denmark
  "IE", // Ireland
  "NO", // Norway
  "NZ", // New Zealand
  "SG", // Singapore
  "SE", // Sweden
  "UY", // Uruguay
];

const longTermButCountries = [
  "AR", // Argentina
  "BR", // Brazil
  "CO", // Colombia
  "DO", // Dominican Republic
  "EC", // Ecuador
  "FR", // France
  "GB", // United Kingdom
  "ID", // Indonesia
  "KZ", // Kazakhstan
  "LY", // Libya
  "MX", // Mexico
  "MY", // Malaysia
  "PE", // Peru
  "TM", // Turkmenistan
  "TR", // Turkey
  "US", // United States
  "ZA"  // South Africa
]

const mediumTermCountries = [
  "AT", // Austria
  "CH", // Switzerland
  "CU", // Cuba
  "DE", // Germany
  "ES", // Spain
  "FI", // Finland
  "JP", // Japan
  "KR", // South Korea
  "TH", // Thailand
  "NL", // Netherlands
  "PR", // Puerto Rico
  "PT", // Portugal
  "TW", // Taiwan
];

const mediumTermButCountries = [
  "BG", // Bulgaria
  "BY", // Belarus
  "CN", // China
  "GR", // Greece
  "HR", // Croatia
  "CZ", // Czechia
  "HU", // Hungary
  "IT", // Italy
  "PL", // Poland
  "RO", // Romania
  "RS", // Serbia
  "RU", // Russia
]

export { longTermCountries, longTermButCountries, mediumTermCountries, mediumTermButCountries};
