import React from "react";
import { Alert } from "react-bootstrap";
import { iso2ToName } from "../data/helpers";

const ResultSentence = (props) => {
  let spokenLanguagesString = "";
  let passportsString = "";
  let personalCountriesRecommendationString = "";
  let personalCitiesRecommendationString = "";

  props.spokenLanguages.forEach((element) => {
    element = element.replace(/\s/g, '\xa0'); // make white space unbreakable
    spokenLanguagesString += element + ", ";
  });
  spokenLanguagesString = spokenLanguagesString.replace(/,\s*$/, ""); // remove last comma and whitespace with regexp

  props.passports.forEach((element) => {
    element = element.replace(/\s/g, '\xa0'); // make white space unbreakable
    passportsString += element + ", ";
  });
  passportsString = passportsString.replace(/,\s*$/, ""); // remove last comma and whitespace with regexp

  props.personalCountriesRecommendation.forEach((element) => {
    let name = iso2ToName[element];
    name = name.replace(/\s/g, '\xa0'); // make white space unbreakable
    personalCountriesRecommendationString += name + ", ";
  });
  personalCountriesRecommendationString = personalCountriesRecommendationString.replace(
    /,\s*$/,
    ""
  ); // remove last comma and whitespace with regexp

  props.personalCitiesRecommendation.forEach((element) => {
    element = element.replace(/\s/g, '\xa0'); // make white space unbreakable
    personalCitiesRecommendationString += element + ", ";
  });
  personalCitiesRecommendationString = personalCitiesRecommendationString.replace(/,\s*$/, ""); // remove last comma and whitespace with regexp


  return (
    <Alert className="text-center pb-0" variant="info">
      <p className="mb-0">
        Based on your language preferences of{" "}
        {spokenLanguagesString || "neutral"} and your passport options of &nbsp;
        {passportsString || "none"} consider the following
        countries as long-term options to relocate to:{" "}
      </p>
      <p className="text-primary">
        <b>{personalCountriesRecommendationString}</b>
      </p>
      <p className="mb-0">
          Within those countries, consider the following cities:
      </p>
      <p className="text-primary mb-2">
          <b>{personalCitiesRecommendationString}</b>
      </p>
    </Alert>
  );
};

export default ResultSentence;
