import React, { useState } from "react";
import { Button, Card, Collapse, Form } from "react-bootstrap";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import { passportToCountries } from "../data/helpers";

const Passport = (props) => {
  const countries = [];
  for (let country in passportToCountries) {
    countries.push(country);
  }
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Card bg="light">
        <Card.Body style={{padding: "12px 12px 16px 12px"}}>
          <Button
            onClick={toggle}
            className="float-right"
            variant="outline-primary"
            size="sm"
          >
            {isOpen && <ChevronUp />}
            {!isOpen && <ChevronDown />}
          </Button>
          <Card.Title>Set passports to personalize recommendation</Card.Title>
          <Collapse in={isOpen}>
            <Form>
              <Form.Group controlId="formPassport">
                {countries.map((item, i) => (
                  <Form.Check
                    type="checkbox"
                    checked={props.checked.includes(item) ? true : false}
                    onChange={props.handleChange}
                    name={item}
                    id={item}
                    key={i}
                    label={item}
                  />
                ))}
              </Form.Group>
            </Form>
          </Collapse>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Passport;
