// Cities with over 1 million metro population of countries with over 5000$ GDP per capita
// Names shown for best cities in country

const cityMarkers = [
{name: "Adana",                         country: "TR", coordinates: [  35.3308,   36.9914], showName: false, markerOffset:   4},
{name: "Adelaide",                      country: "AU", coordinates: [ 138.6007,  -34.9285], showName: false, markerOffset:  -3},
{name: "Almaty",                        country: "KZ", coordinates: [  76.8512,   43.2220], showName: true,  markerOffset:   5},
{name: "Amsterdam",                     country: "NL", coordinates: [   4.8945,   52.3667], showName: true,  markerOffset:   0},
{name: "Ankara",                        country: "TR", coordinates: [  32.8597,   39.9334], showName: false, markerOffset:  -1},
{name: "Antalya",                       country: "TR", coordinates: [  30.7133,   36.8969], showName: false, markerOffset:  13},
{name: "Arequipa",                      country: "PE", coordinates: [ -71.5375,  -16.4090], showName: false, markerOffset:   0},
{name: "Ashgabat",                      country: "TM", coordinates: [  58.3261,   37.9601], showName: false, markerOffset:   5},
{name: "Athens",                        country: "GR", coordinates: [  23.7275,   37.9838], showName: false, markerOffset:   5},
{name: "Atlanta",                       country: "US", coordinates: [ -84.3880,   33.7490], showName: false, markerOffset:   5},
{name: "Auckland",                      country: "NZ", coordinates: [ 174.7633,  -36.8485], showName: true,  markerOffset:  -4},
{name: "Austin",                        country: "US", coordinates: [ -97.7431,   30.2672], showName: true,  markerOffset:  -2},
{name: "Baotou",                        country: "CN", coordinates: [ 109.8403,   40.6580], showName: false, markerOffset:   0},
{name: "Bangkok",                       country: "TH", coordinates: [ 100.5018,   13.7563], showName: false, markerOffset:  -4},
{name: "Bandung",                       country: "ID", coordinates: [ 107.6191,   -6.9175], showName: false, markerOffset:   4},
{name: "Barcelona",                     country: "ES", coordinates: [   2.1734,   41.3851], showName: true,  markerOffset:  -3},
{name: "Barranquilla",                  country: "CO", coordinates: [ -74.8070,   11.0041], showName: false, markerOffset:   0},
{name: "Bekasi",                        country: "ID", coordinates: [ 106.9756,   -6.2383], showName: false, markerOffset:   0},
{name: "Belo Horizonte",                country: "BR", coordinates: [ -43.9345,  -19.9167], showName: false, markerOffset:   0},
{name: "Belgrade",                      country: "RS", coordinates: [  20.4489,   44.7866], showName: false, markerOffset:  13},
{name: "Beijing",                       country: "CN", coordinates: [ 116.4074,   39.9042], showName: false, markerOffset:   5},
{name: "Benghazi",                      country: "LY", coordinates: [  20.0868,   32.1194], showName: false, markerOffset:   0},
{name: "Berlin",                        country: "DE", coordinates: [  13.4050,   52.5200], showName: true,  markerOffset:  -4},
{name: "Brisbane",                      country: "AU", coordinates: [ 153.0251,  -27.4698], showName: false, markerOffset:  -4},
{name: "Brussels",                      country: "BE", coordinates: [   4.3517,   50.8503], showName: false, markerOffset:   4},
{name: "Bogota",                        country: "CO", coordinates: [ -74.0721,    4.7110], showName: false, markerOffset:   7},
{name: "Boston",                        country: "US", coordinates: [ -71.0589,   42.3601], showName: false, markerOffset:  -2},
{name: "Bucharest",                     country: "RO", coordinates: [  26.1025,   44.4268], showName: false, markerOffset:   8},
{name: "Buenos Aires",                  country: "AR", coordinates: [ -58.3816,  -34.6037], showName: true,  markerOffset:   7},
{name: "Budapest",                      country: "HU", coordinates: [  19.0402,   47.4979], showName: false, markerOffset:   8},
{name: "Bursa",                         country: "TR", coordinates: [  29.0610,   40.1885], showName: false, markerOffset:   0},
{name: "Busan",                         country: "KR", coordinates: [ 129.0756,   35.1796], showName: false, markerOffset:   5},
{name: "Calgary",                       country: "CA", coordinates: [-114.0719,   51.0447], showName: false, markerOffset: -4},
{name: "Cali",                          country: "CO", coordinates: [ -76.5320,    3.4516], showName: false, markerOffset:   0},
{name: "Cape Town",                     country: "ZA", coordinates: [  18.4241,  -33.9249], showName: true,  markerOffset:   5},
{name: "Cartagena",                     country: "CO", coordinates: [ -75.4794,   10.3910], showName: false, markerOffset:   0},
{name: "Changsha",                      country: "CN", coordinates: [ 112.9388,   28.2282], showName: false, markerOffset:   0},
{name: "Changchun",                     country: "CN", coordinates: [ 125.3235,   43.8171], showName: false, markerOffset:   0},
{name: "Changwon",                      country: "KR", coordinates: [ 128.6811,   35.2281], showName: false, markerOffset:   0},
{name: "Changzhou",                     country: "CN", coordinates: [ 119.9741,   31.8112], showName: false, markerOffset:   0},
{name: "Chelyabinsk",                   country: "RU", coordinates: [  61.4368,   55.1644], showName: false, markerOffset:   0},
{name: "Chengdu",                       country: "CN", coordinates: [ 104.0668,   30.5728], showName: false, markerOffset:   0},
{name: "Chicago",                       country: "US", coordinates: [ -87.6298,   41.8781], showName: false, markerOffset:  -2},
{name: "Chongqing",                     country: "CN", coordinates: [ 106.9123,   29.4316], showName: false, markerOffset:   0},
{name: "Ciudad Juarez",                 country: "MX", coordinates: [-106.4245,   31.6904], showName: false, markerOffset:   0},
{name: "Ciudad Nezahualcoyotl",         country: "MX", coordinates: [ -98.9897,   19.3995], showName: false, markerOffset:   0},
{name: "Cleveland",                     country: "US", coordinates: [ -81.6944,   41.4993], showName: false, markerOffset:   2},
{name: "Cologne",                       country: "DE", coordinates: [   6.9603,   50.9375], showName: false, markerOffset:   8},
{name: "Concepcion",                    country: "CL", coordinates: [ -73.0444,  -36.8201], showName: false, markerOffset:   0},
{name: "Copenhagen",                    country: "DK", coordinates: [  12.5683,   55.6761], showName: true,  markerOffset:  -4},
{name: "Cordoba",                       country: "AR", coordinates: [ -64.1888,  -31.4201], showName: false, markerOffset:  -6},
{name: "Curitiba",                      country: "BR", coordinates: [ -49.3044,  -25.4809], showName: false, markerOffset:   7},
{name: "Dallas",                        country: "US", coordinates: [ -96.7970,   32.7767], showName: false, markerOffset:  -4},
{name: "Denver",                        country: "US", coordinates: [-104.9903,   39.7392], showName: false, markerOffset:   6},
{name: "Daegu",                         country: "KR", coordinates: [ 128.6014,   35.8714], showName: false, markerOffset:   6},
{name: "Daejeon",                       country: "KR", coordinates: [ 127.3845,   36.3504], showName: false, markerOffset:   2},
{name: "Dalian",                        country: "CN", coordinates: [ 121.6147,   38.9140], showName: false, markerOffset:   0},
{name: "Depok",                         country: "ID", coordinates: [ 106.7942,   -6.4025], showName: false, markerOffset:   0},
{name: "Detroit",                       country: "US", coordinates: [ -83.0458,   42.3314], showName: false, markerOffset:  -3},
{name: "Dongguan",                      country: "CN", coordinates: [ 113.7518,   23.0207], showName: false, markerOffset:   0},
{name: "Dublin",                        country: "IE", coordinates: [  -6.2603,   53.3498], showName: true,  markerOffset:  -7},
{name: "Ecatepec",                      country: "MX", coordinates: [ -99.0365,   19.6058], showName: false, markerOffset:   0},
{name: "Fortaleza",                     country: "BR", coordinates: [ -38.5270,   -3.7327], showName: false, markerOffset:   7},
{name: "Foshan",                        country: "CN", coordinates: [ 113.1214,   23.0215], showName: false, markerOffset:   0},
{name: "Fukuoka",                       country: "JP", coordinates: [ 130.4017,   33.5902], showName: false, markerOffset:   9},
{name: "Fuzhou",                        country: "CN", coordinates: [ 119.2965,   26.0745], showName: false, markerOffset:   0},
{name: "Gaziantep",                     country: "TR", coordinates: [  37.3781,   37.0660], showName: false, markerOffset:   9},
{name: "Goyang",                        country: "KR", coordinates: [ 126.8320,   37.6584], showName: false, markerOffset:   0},
{name: "Guadalajara",                   country: "MX", coordinates: [-103.3496,   20.6597], showName: false, markerOffset:   0},
{name: "Guangzhou",                     country: "CN", coordinates: [ 113.2644,   23.1291], showName: false, markerOffset:   0},
{name: "Guayaquil",                     country: "EC", coordinates: [ -79.8891,   -2.1894], showName: false, markerOffset:   7},
{name: "Guiyang",                       country: "CN", coordinates: [ 106.6302,   26.6477], showName: false, markerOffset:   0},
{name: "Gwangju",                       country: "KR", coordinates: [ 126.8526,   35.1595], showName: false, markerOffset:   0},
{name: "Hamburg",                       country: "DE", coordinates: [   9.9937,   53.5511], showName: false, markerOffset:   5},
{name: "Handan",                        country: "CN", coordinates: [ 114.5390,   36.6257], showName: false, markerOffset:   0},
{name: "Hangzhou",                      country: "CN", coordinates: [ 120.1551,   30.2741], showName: false, markerOffset:   0},
{name: "Harbin",                        country: "CN", coordinates: [ 126.5350,   45.8038], showName: false, markerOffset:   0},
{name: "Havana",                        country: "CU", coordinates: [ -82.3666,   23.1136], showName: true,  markerOffset:   5},
{name: "Hefei",                         country: "CN", coordinates: [ 117.2272,   31.8206], showName: false, markerOffset:   0},
{name: "Helsinki",                      country: "FI", coordinates: [  24.9384,   60.1699], showName: true,  markerOffset:   0},
{name: "Hiroshima",                     country: "JP", coordinates: [ 132.4553,   34.3853], showName: false, markerOffset:   0},
{name: "Hong Kong",                     country: "CN", coordinates: [ 114.1694,   22.3193], showName: false, markerOffset:   6},
{name: "Honolulu",                      country: "US", coordinates: [-157.8583,   21.3069], showName: false, markerOffset:   5},
{name: "Houston",                       country: "US", coordinates: [ -95.3698,   29.7604], showName: false, markerOffset:   4},
{name: "Huai'an",                       country: "CN", coordinates: [ 119.1132,   33.5511], showName: false, markerOffset:   0},
{name: "Huizhou",                       country: "CN", coordinates: [ 114.4158,   23.1123], showName: false, markerOffset:   0},
{name: "Jinan",                         country: "CN", coordinates: [ 117.1201,   36.6512], showName: false, markerOffset:   0},
{name: "Johannesburg",                  country: "ZA", coordinates: [  28.0473,  -26.2041], showName: false, markerOffset:   4},
{name: "Incheon",                       country: "KR", coordinates: [ 126.7052,   37.4563], showName: false, markerOffset:   0},
{name: "Istanbul",                      country: "TR", coordinates: [  28.9784,   41.0082], showName: true,  markerOffset:   8},
{name: "Izmir",                         country: "TR", coordinates: [  27.1428,   38.4237], showName: false, markerOffset:   9},
{name: "Jakarta",                       country: "ID", coordinates: [ 106.8456,   -6.2088], showName: false, arkerOffset:   -3},
{name: "Kansas City",                   country: "US", coordinates: [ -94.5786,   39.0997], showName: false, markerOffset:  -4},
{name: "Kaohsiung",                     country: "TW", coordinates: [ 120.3355,   22.6351], showName: false, markerOffset:   0},
{name: "Kawasaki",                      country: "JP", coordinates: [ 139.7029,   35.5308], showName: false, markerOffset:   0},
{name: "Kayseri",                       country: "TR", coordinates: [  35.4826,   38.7205], showName: false, markerOffset:   0},
{name: "Kazan",                         country: "RU", coordinates: [  49.0661,   55.8304], showName: false, markerOffset:   0},
{name: "Kobe",                          country: "JP", coordinates: [ 135.1956,   34.6901], showName: false, markerOffset:   0},
{name: "Konya",                         country: "TR", coordinates: [  32.4932,   37.8746], showName: false, markerOffset:   0},
{name: "Krakow",                        country: "PL", coordinates: [  19.9450,   50.0647], showName: false, markerOffset:   0},
{name: "Krasnodar",                     country: "RU", coordinates: [  38.9872,   45.0393], showName: false, markerOffset:   0},
{name: "Krasnoyarsk",                   country: "RU", coordinates: [  92.8932,   56.0153], showName: false, markerOffset:   5},
{name: "Kuala Lumpur",                  country: "MY", coordinates: [ 101.6869,    3.1390], showName: true,  markerOffset:  -4},
{name: "Kunming",                       country: "CN", coordinates: [ 102.8329,   24.8801], showName: false, markerOffset:   0},
{name: "Kyoto",                         country: "JP", coordinates: [ 135.7681,   35.0116], showName: false, markerOffset:   0},
{name: "Lanzhou",                       country: "CN", coordinates: [ 103.8343,   36.0611], showName: false, markerOffset:   0},
{name: "Leon",                          country: "MX", coordinates: [-101.6860,   21.1250], showName: false, markerOffset:   0},
{name: "Lima",                          country: "PE", coordinates: [ -77.0428,  -12.0464], showName: true,  markerOffset:   7},
{name: "Lisbon",                        country: "PT", coordinates: [  -9.1393,   38.7223], showName: true,  markerOffset:  -3},
{name: "Liuzhou",                       country: "CN", coordinates: [ 109.4286,   24.3263], showName: false, markerOffset:   0},
{name: "London",                        country: "GB", coordinates: [  -0.1278,   51.5074], showName: false, markerOffset:  -3},
{name: "Los Angeles",                   country: "US", coordinates: [-118.2437,   34.0522], showName: false, markerOffset:   3},
{name: "Luoyang",                       country: "CN", coordinates: [ 112.4539,   34.6202], showName: false, markerOffset:   0},
{name: "Lyon",                          country: "FR", coordinates: [   4.8357,   45.7640], showName: false, markerOffset:   5},
{name: "Madrid",                        country: "ES", coordinates: [  -3.7038,   40.4168], showName: false, markerOffset:   7},
{name: "Makassar",                      country: "ID", coordinates: [ 119.4327,   -5.1477], showName: false, arkerOffset:    5},
{name: "Manaus",                        country: "BR", coordinates: [ -60.0217,   -3.1190], showName: false, markerOffset:   7},
{name: "Manchester",                    country: "GB", coordinates: [  -2.2426,   53.4808], showName: false, markerOffset:  -3},
{name: "Medan",                         country: "ID", coordinates: [  98.6722,    3.5952], showName: false, markerOffset:  -4},
{name: "Medellin",                      country: "CO", coordinates: [ -75.5742,    6.2486], showName: true,  markerOffset:  -4},
{name: "Melbourne",                     country: "AU", coordinates: [ 144.9631,  -37.8136], showName: false, markerOffset:   5},
{name: "Mexico City",                   country: "MX", coordinates: [ -99.1332,   19.4326], showName: true,  markerOffset:  -4},
{name: "Miami",                         country: "US", coordinates: [ -80.1918,   25.7617], showName: true,  markerOffset:  -4},
{name: "Milan",                         country: "IT", coordinates: [   9.1900,   45.4642], showName: false, markerOffset:   0},
{name: "Minneapolis",                   country: "US", coordinates: [ -93.2650,   44.9778], showName: false, markerOffset:  -3},
{name: "Minsk",                         country: "BY", coordinates: [  27.5590,   53.9006], showName: false, markerOffset:  -1},
{name: "Monterrey",                     country: "MX", coordinates: [-100.3161,   25.6866], showName: false, markerOffset:   7},
{name: "Montevideo",                    country: "UY", coordinates: [ -56.1645,  -34.9011], showName: false, markerOffset:  -3},
{name: "Montreal",                      country: "CA", coordinates: [ -73.5673,   45.5017], showName: true,  markerOffset:  -4},
{name: "Moscow",                        country: "RU", coordinates: [  37.6173,   55.7558], showName: false, markerOffset:   8},
{name: "Munich",                        country: "DE", coordinates: [  11.5820,   48.1351], showName: false, markerOffset:   5},
{name: "Nagoya",                        country: "JP", coordinates: [ 136.9066,   35.1815], showName: false, markerOffset:   0},
{name: "Nanchang",                      country: "CN", coordinates: [ 115.8582,   28.6829], showName: false, markerOffset:   0},
{name: "Nanjing",                       country: "CN", coordinates: [ 118.7969,   32.0603], showName: false, markerOffset:   0},
{name: "Nanning",                       country: "CN", coordinates: [ 108.3665,   22.8170], showName: false, markerOffset:   0},
{name: "Nantong",                       country: "CN", coordinates: [ 120.8943,   31.9802], showName: false, markerOffset:   0},
{name: "New York City",                 country: "US", coordinates: [ -74.0060,   40.7128], showName: false, markerOffset:   6},
{name: "Ningbo",                        country: "CN", coordinates: [ 121.5440,   29.8683], showName: false, markerOffset:   0},
{name: "Nizhny Novgorod",               country: "RU", coordinates: [  43.9361,   56.2965], showName: false, markerOffset:   0},
{name: "Novosibirsk",                   country: "RU", coordinates: [  82.9357,   55.0084], showName: false, markerOffset:   0},
{name: "Nur-Sultan",                    country: "KZ", coordinates: [  71.4704,   51.1605], showName: false, markerOffset:   0},
{name: "Omsk",                          country: "RU", coordinates: [  73.3242,   54.9885], showName: false, markerOffset:   0},
{name: "Orlando",                       country: "US", coordinates: [ -81.3792,   28.5383], showName: false, markerOffset:  -5},
{name: "Osaka",                         country: "JP", coordinates: [ 135.5023,   34.6937], showName: true,  markerOffset:   8},
{name: "Oslo",                          country: "NO", coordinates: [  10.7522,   59.9139], showName: false, markerOffset:  -6},
{name: "Palembang",                     country: "ID", coordinates: [ 104.7754,   -2.9761], showName: false, markerOffset:  -3},
{name: "Panama City",                   country: "PA", coordinates: [ -79.5199,    8.9824], showName: false, markerOffset:   0},
{name: "Paris",                         country: "FR", coordinates: [   2.3522,   48.8566], showName: false, markerOffset:   4},
{name: "Perm",                          country: "RU", coordinates: [  56.2668,   58.0297], showName: false, markerOffset:   0},
{name: "Perth",                         country: "AU", coordinates: [ 115.8605,  -31.9505], showName: false, markerOffset:   7},
{name: "Philadelphia",                  country: "US", coordinates: [ -75.1652,   39.9526], showName: false, markerOffset:   7},
{name: "Phoenix",                       country: "US", coordinates: [-112.0740,   33.4484], showName: false, markerOffset:  -3},
{name: "Pittsburgh",                    country: "US", coordinates: [ -79.9959,   40.4406], showName: false, markerOffset:   7},
{name: "Portland",                      country: "US", coordinates: [-122.6750,   45.5051], showName: false, markerOffset:   7},
{name: "Porto Alegre",                  country: "BR", coordinates: [ -51.2177,  -30.0346], showName: false, markerOffset:   5},
{name: "Prague",                        country: "CZ", coordinates: [  14.4378,   50.0755], showName: false, markerOffset:  -3},
{name: "Puebla",                        country: "MX", coordinates: [ -98.2063,   19.0414], showName: false, markerOffset:   7},
{name: "Qingdao",                       country: "CN", coordinates: [ 120.3826,   36.0671], showName: false, markerOffset:   0},
{name: "Quito",                         country: "EC", coordinates: [ -78.4678,   -0.1807], showName: false, markerOffset:   7},
{name: "Recife",                        country: "BR", coordinates: [ -34.9286,   -8.0522], showName: false, markerOffset:   7},
{name: "Rio de Janeiro",                country: "BR", coordinates: [ -43.1729,  -22.9068], showName: true,  markerOffset:  -4},
{name: "Rome",                          country: "IT", coordinates: [  12.4964,   41.9028], showName: false, markerOffset:   5},
{name: "Rosario",                       country: "AR", coordinates: [ -60.6930,  -32.9587], showName: false, markerOffset:   0},
{name: "Rostov-on-Don",                 country: "RU", coordinates: [  39.7015,   47.2357], showName: false, markerOffset:   0},
{name: "Saint Petersburg",              country: "RU", coordinates: [  30.3351,   59.9343], showName: false, markerOffset:   4},
{name: "Saitama",                       country: "JP", coordinates: [ 139.6455,   35.8616], showName: false, markerOffset:   0},
{name: "Salt Lake City",                country: "US", coordinates: [-111.8910,   40.7608], showName: false, markerOffset:   4},
{name: "Salvador",                      country: "BR", coordinates: [ -38.5016,  -12.9777], showName: false, markerOffset:   7},
{name: "Samara",                        country: "RU", coordinates: [  50.2212,   53.2415], showName: false, markerOffset:   0},
{name: "San Antonio",                   country: "US", coordinates: [ -98.4936,   29.4241], showName: false, markerOffset:   7},
{name: "San Diego",                     country: "US", coordinates: [-117.1611,   32.7157], showName: false, markerOffset:   5},
{name: "San Francisco",                 country: "US", coordinates: [-122.4194,   37.7749], showName: false, markerOffset:  -4},
{name: "San Jose",                      country: "US", coordinates: [-121.8863,   37.3382], showName: false, markerOffset:   3},
{name: "San Juan",                      country: "PR", coordinates: [ -66.1057,   18.4655], showName: false, markerOffset:   0},
{name: "Santiago",                      country: "CL", coordinates: [ -70.6693,  -33.4489], showName: true,  markerOffset:   5},
{name: "Santiago de los Caballeros",    country: "DO", coordinates: [ -70.6931,   19.4792], showName: false, markerOffset:   0},
{name: "Santo Domingo",                 country: "DO", coordinates: [ -69.9312,   18.4861], showName: false, markerOffset:   5},
{name: "Sao Paulo",                     country: "BR", coordinates: [ -46.6333,  -23.5505], showName: true,  markerOffset:   7},
{name: "Sapporo",                       country: "JP", coordinates: [ 141.3545,   43.0618], showName: false, markerOffset:   0},
{name: "Seattle",                       country: "US", coordinates: [-122.3321,   47.6062], showName: false, markerOffset:   5},
{name: "Semarang",                      country: "ID", coordinates: [ 110.4381,   -7.0051], showName: false, markerOffset:   0},
{name: "Sendai",                        country: "JP", coordinates: [ 140.8694,   38.2682], showName: false, markerOffset:   0},
{name: "Seoul",                         country: "KR", coordinates: [ 126.9780,   37.5665], showName: true,  markerOffset:  -3},
{name: "Seville",                       country: "ES", coordinates: [  -5.9845,   37.3891], showName: false, markerOffset:   7},
{name: "Shanghai",                      country: "CN", coordinates: [ 121.4737,   31.2304], showName: false, markerOffset:   7},
{name: "Shantou",                       country: "CN", coordinates: [ 116.6820,   23.3541], showName: false, markerOffset:   0},
{name: "Shaoxing",                      country: "CN", coordinates: [ 120.5861,   29.9958], showName: false, markerOffset:   0},
{name: "Shenyang",                      country: "CN", coordinates: [ 123.4315,   41.8057], showName: false, markerOffset:   0},
{name: "Shenzhen",                      country: "CN", coordinates: [ 114.0579,   22.5431], showName: false, markerOffset:   1},
{name: "Shijiazhuang",                  country: "CN", coordinates: [ 114.5149,   38.0428], showName: false, markerOffset:   0},
{name: "Shymkent",                      country: "KZ", coordinates: [  69.5901,   42.3417], showName: false, markerOffset:   0},
{name: "Singapore",                     country: "SG", coordinates: [ 103.8198,    1.3521], showName: true,  markerOffset:   5},
{name: "Sofia",                         country: "BG", coordinates: [  23.3219,   42.6977], showName: false, arkerOffset:   12},
{name: "South Tangerang",               country: "ID", coordinates: [ 106.7113,   -6.2835], showName: false, markerOffset:   0},
{name: "Stockholm",                     country: "SE", coordinates: [  18.0686,   59.3293], showName: true,  markerOffset:  -3},
{name: "Surabaya",                      country: "ID", coordinates: [ 112.7521,   -7.2575], showName: false, markerOffset:   0},
{name: "Suwon",                         country: "KR", coordinates: [ 127.0286,   37.2636], showName: false, markerOffset:   0},
{name: "Sydney",                        country: "AU", coordinates: [ 151.2093,  -33.8688], showName: true,  markerOffset:  -4},
{name: "Taichung",                      country: "TW", coordinates: [ 120.6736,   24.1477], showName: false, markerOffset:   0},
{name: "Taipei",                        country: "TW", coordinates: [ 121.5654,   25.0330], showName: false, markerOffset:  -4},
{name: "Taiyuan",                       country: "CN", coordinates: [ 112.5489,   37.8706], showName: false, markerOffset:   0},
{name: "Tangerang",                     country: "ID", coordinates: [ 106.6403,   -6.1702], showName: false, markerOffset:   0},
{name: "Tangshan",                      country: "CN", coordinates: [ 118.1802,   39.6309], showName: false, markerOffset:   0},
{name: "The Hague",                     country: "NL", coordinates: [   4.3007,   52.0705], showName: false, markerOffset:  -3},
{name: "Tianjin",                       country: "CN", coordinates: [ 117.3616,   39.3434], showName: false, markerOffset:   0},
{name: "Tijuana",                       country: "MX", coordinates: [-117.0382,   32.5149], showName: false, markerOffset:   0},
{name: "Tokyo",                         country: "JP", coordinates: [ 139.6503,   35.6762], showName: false, markerOffset:  -4},
{name: "Toronto",                       country: "CA", coordinates: [ -79.3832,   43.6532], showName: false, markerOffset:  -2},
{name: "Tripoli",                       country: "LY", coordinates: [  13.1913,   32.8872], showName: false, markerOffset:   0},
{name: "Ufa",                           country: "RU", coordinates: [  55.9721,   54.7388], showName: false, markerOffset:   0},
{name: "Ulsan",                         country: "KR", coordinates: [ 129.3114,   35.5384], showName: false, markerOffset:   0},
{name: "Ürümqi",                        country: "CN", coordinates: [  87.6168,   43.8256], showName: false, markerOffset:   0},
{name: "Valencia",                      country: "ES", coordinates: [  -0.3763,   39.4699], showName: false, markerOffset:  -4},
{name: "Vancouver",                     country: "CA", coordinates: [-123.1207,   49.2827], showName: false, markerOffset:  -3},
{name: "Vienna",                        country: "AT", coordinates: [  16.3738,   48.2082], showName: false, markerOffset:   4},
{name: "Volgograd",                     country: "RU", coordinates: [  44.5133,   48.7080], showName: false, markerOffset:   0},
{name: "Voronezh",                      country: "RU", coordinates: [  39.2089,   51.6755], showName: false, markerOffset:   0},
{name: "Warsaw",                        country: "PL", coordinates: [  21.0122,   52.2297], showName: false, markerOffset:  -2},
{name: "Washington, D.C.",              country: "US", coordinates: [ -77.0369,   38.9072], showName: false, markerOffset:   7},
{name: "Weifang",                       country: "CN", coordinates: [ 119.1617,   36.7070], showName: false, markerOffset:   0},
{name: "Wenzhou",                       country: "CN", coordinates: [ 120.6994,   27.9938], showName: false, markerOffset:   0},
{name: "Wuhan",                         country: "CN", coordinates: [ 114.3055,   30.5928], showName: false, markerOffset:   0},
{name: "Wuxi",                          country: "CN", coordinates: [ 120.3119,   31.4912], showName: false, markerOffset:   0},
{name: "Xi'an",                         country: "CN", coordinates: [ 108.9398,   34.3416], showName: false, markerOffset:   0},
{name: "Xiamen",                        country: "CN", coordinates: [ 118.0894,   24.4798], showName: false, markerOffset:   0},
{name: "Yantai",                        country: "CN", coordinates: [ 121.4479,   37.4645], showName: false, markerOffset:   0},
{name: "Yekaterinburg",                 country: "RU", coordinates: [  60.6057,   56.8389], showName: false, markerOffset:   0},
{name: "Yokohama",                      country: "JP", coordinates: [ 139.6380,   35.4437], showName: false, markerOffset:   5},
{name: "Zagreb",                        country: "HR", coordinates: [  15.9819,   45.8150], showName: false, markerOffset:   8},
{name: "Zapopan",                       country: "MX", coordinates: [-103.4165,   20.6720], showName: false, markerOffset:   0},
{name: "Zhengzhou",                     country: "CN", coordinates: [ 113.6253,   34.7466], showName: false, markerOffset:   0},
{name: "Zibo",                          country: "CN", coordinates: [ 118.0550,   36.8135], showName: false, markerOffset:   0},
{name: "Zhongshan",                     country: "CN", coordinates: [ 113.3928,   22.5176], showName: false, markerOffset:   0},
{name: "Zurich",                        country: "CH", coordinates: [   8.5417,   47.3769], showName: false, markerOffset:   4},
    ];

export default cityMarkers;