import React, { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";

export default function SubscribeForm() {
  const [email, setEmail] = useState(null);
  const [done, setDone] = useState(false);


  // https://github.com/jamiewilson/form-to-google-sheets/blob/master/README.md
  const submit = (e) => {
    e.preventDefault();
    setDone(true);
    const scriptURL =
      "https://script.google.com/macros/s/AKfycbw3jrsT4Lwdjndvgrh1T_EzUoOcX4G7f311FqisgffQC6jN0ea8/exec";
    const formData = new FormData();
    formData.append('email', email);
      fetch(scriptURL, { method: "POST", body: formData })
      .catch((error) => console.error("Error!", error.message));
  };

  return (
    <div>
      <Card bg="light">
        <Card.Body style={{padding: "8px"}}>
          {!done ? (
            <Form>
              <div className="d-flex justify-content-center">
                <Form.Control
                  style={{ width: "350px" }}
                  type="email"
                  placeholder="Enter email"
                  className="mr-2"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Button variant="primary" type="submit" onClick={submit}>
                  Subscribe to updates!
                </Button>
              </div>
            </Form>
          ) : (
            <div className="text-center text-muted">
              Thanks for your trust! If enough people show interest, I'll invest
              more time into this project.
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}
