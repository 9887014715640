import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Graticule,
  Sphere,
  Marker,
  ZoomableGroup,
} from "react-simple-maps";
import { PatternLines } from "@vx/pattern";

import cityMarkers from "../data/cityMarkers";

const geoUrl = "./world-110m.json";
//https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json

const MapChart = (props) => {
  return (
    <div>
      <div className="row small pt-4 pl-1 mr-1">
        <div className="col-xs-12 col-sm-6 col-md-5 pr-0">
          <b>Map Legend</b>
          <br />
          <ul className="legend">
            <li>
              <span className="green-striped-sample"></span>
              <span className="yellow-striped-sample"></span>
              <span className="orange-striped-sample"></span>
              <span className="red-striped-sample"></span> country
              recommendations based on filters set below
            </li>
            <li>
              <span className="green-sample"></span> long-term option due to
              healthy population pyramid
            </li>
            <li>
              <span className="yellow-sample"></span> long-term option due to
              healthy population pyramid, but with caveats
            </li>
            <li>
              <span className="orange-sample"></span> only medium-term option
              due to unhealthy population pyramid
            </li>
            <li>
              <span className="red-sample"></span> only medium-term option due
              to unhealthy population pyramid, plus caveats
            </li>
            <li>
              <span className="gray-sample"></span> less than 5000$ GDP per
              capita or no city with over 1 million metro population
            </li>
            <li>
              <span className="dot-sample"></span> cities with over 1 million
              metro population (for mainland China only top 50 marked)
            </li>
            <li>
              <svg width="6em" height="1em">
                <text
                  style={{
                    fontFamily: "Fira Code",
                    fontWeight: "bold",
                    fill: "white",
                    stroke: "white",
                    strokeOpacity: "1",
                    strokeWidth: "3",
                    fontSize: "0.7rem",
                  }}
                  y="8.5"
                >
                  city names
                </text>
                <text
                  style={{
                    fontFamily: "Fira Code",
                    fontWeight: "bold",
                    fill: "var(--main-darkblue)",
                    fontSize: "0.6rem",
                  }}
                  y="8.5"
                >
                  city names
                </text>
              </svg>{" "}
              = recommended cities within recommended countries
            </li>
          </ul>
        </div>
        <div className="col-xs-12 col-sm-3 col-md-3 pr-0">
          <b>Caveats</b>
          <ul className="legend">
            high violent crime rate, racism, failing institutions, widespread corruption or low trust environment, , looming or actual hyperinflation, looming or actual police state, looming or actual civil war, looming or actual communism
          </ul>
        </div>
        <div className="col-xs-12 col-sm-3 col-md-4 pr-0">
          <b>Remarks</b>
          <ul className="legend">
            <li>
              There are most likely great options below the consideration
              thresholds!
            </li>
            <li>
              UAE, Saudi Arabia, Oman, Bahrain, Qatar, and Equatorial Guinea
              have not been considered due to ridiculous amount of male migrant
              workers.
            </li>
            <li>
              Singapore has been included despite unhealthy population pyramid
              as immigration easily balances it out.
            </li>
            <li>
              <kbd>crtl + mousescroll</kbd> or pinch to zoom map!
            </li>
          </ul>
        </div>
      </div>

      <div>
        <ComposableMap
          height={500}
          width={800}
          projection="geoEqualEarth"
          projectionConfig={{ rotate: [0, 0], scale: 130 }}
        >
          <ZoomableGroup zoom={1.2} minZoom={1.2} maxZoom={3} center={[0, 0]}>
            <PatternLines
              id="marked"
              height={5}
              width={5}
              stroke="var(--main-lightgray)"
              strokeWidth={1.2}
              background="transparent"
              orientation={["diagonal"]}
            />
            <Sphere stroke="var(--main-lightgray)" />
            <Graticule stroke="var(--main-lightgray)" step={[10, 10]} />
            <Geographies
              geography={geoUrl}
              stroke="var(--main-darkgray)"
              strokeWidth={1}
            >
              {({ geographies }) =>
                geographies.map((geo) => {
                  let fill = "";
                  if (props.blue.indexOf(geo.properties.ISO_A2) !== -1) {
                    fill = "var(--main-green)";
                  } else if (
                    props.purple.indexOf(geo.properties.ISO_A2) !== -1
                  ) {
                    fill = "var(--main-yellow)";
                  } else if (
                    props.yellow.indexOf(geo.properties.ISO_A2) !== -1
                  ) {
                    fill = "var(--main-orange)";
                  } else if (props.red.indexOf(geo.properties.ISO_A2) !== -1) {
                    fill = "var(--main-red)";
                  } else {
                    fill = "var(--main-mediumgray)";
                  }

                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill={fill}
                      onClick={() => console.log(geo.properties.ISO_A2)}
                    />
                  );
                })
              }
            </Geographies>
            <Geographies geography={geoUrl} fill="url('#marked')">
              {({ geographies }) =>
                geographies.map((geo) => {
                  if (props.marked.indexOf(geo.properties.ISO_A2) !== -1) {
                    return <Geography key={geo.rsmKey} geography={geo} />;
                  }
                  return null;
                })
              }
            </Geographies>
            {cityMarkers.map(({ _, name, __, coordinates }) => (
              <Marker key={name} coordinates={coordinates}>
                <circle
                  r={0.6}
                  fill="rgb(0,0,0,0.6)"
                  opacity="1"
                  stroke="rgb(0,0,0,0.6)"
                  strokeOpacity="1"
                  strokeWidth={1}
                />
              </Marker>
            ))}
            {cityMarkers.map(({ markerOffset, name, coordinates }) => (
              <Marker key={name} coordinates={coordinates}>
                {props.cities.includes(name) && (
                  <g>
                    <text
                      textAnchor="middle"
                      y={markerOffset}
                      style={{
                        fontFamily: "Fira Code",
                        fontWeight: "bold",
                        stroke: "white",
                        strokeOpacity: "1",
                        strokeWidth: "1.5",
                        fontSize: "5px",
                      }}
                    >
                      {name}
                    </text>
                  </g>
                )}
              </Marker>
            ))}
            }
            {cityMarkers.map(({ markerOffset, name, coordinates }) => (
              <Marker key={name} coordinates={coordinates}>
                {props.cities.includes(name) && (
                  <g>
                    <text
                      textAnchor="middle"
                      y={markerOffset}
                      style={{
                        fontFamily: "Fira Code",
                        fontWeight: "bold",
                        fill: "var(--main-darkblue)",
                        fontSize: "5px",
                      }}
                    >
                      {name}
                    </text>
                  </g>
                )}
              </Marker>
            ))}
          </ZoomableGroup>
        </ComposableMap>
      </div>
    </div>
  );
};

export default MapChart;
