const languageToCountries = {
  Arabic: ["LY"],
  Czech: ["CZ"],
  Danish: ["DK"],
  Dutch: ["BE", "NL"],
  English: ["AU", "CA", "GB", "NZ", "PR", "SG", "US"],
  "Finnish/Estonian": ["EE", "FI"],
  French: ["BE", "FR"],
  German: ["AT", "CH", "DE"],
  Greek: ["GR"],
  Hungarian: ["HU"],
  Italian: ["IT"],
  Japanese: ["JP"],
  Kazakh: ["KZ"],
  Korean: ["KR"],
  "Malay/Indonesian": ["ID", "MY", "SG"],
  Mandarin: ["CN", "SG", "TW"],
  Norwegian: ["NO"],
  Polish: ["PL"],
  Portuguese: ["BR", "PT"],
  Romanian: ["RO"],
  "Russian/Belarusian/Ukrainian": ["BY", "RU"],
  Serbian: ["RS"],
  Spanish: ["AR", "CL", "EC", "MX", "PE", "PR", "UY"],
  Swedish: ["SE"],
  Thai: ["TH"],
  "Turkish/Turkmen": ["TR", "TM"],
  "Zulu/Xhosa/Afrikaans": ["ZA"]
};

const passportToCountries = {
  Australia: ["AU", "NZ"], // Trans-Tasman Travel Arrangement 1973
  Austria: ["AT", "BE", "CH", "CZ", "DE", "DK", "ES", "FI", "FR", "GR", "HU", "IT", "NL", "NO", "PL", "PT", "SE"], // EU Schengen Area 1995 Open Border Agreement
  Argentina: ["AR", "BR", "CL", "CO", "EC", "PE"], // MERCOSUR 2002/2009 Residence Agreement
  Belarus: ["BY"],
  Belgium: ["AT", "BE", "CH", "CZ", "DE", "DK", "ES", "FI", "FR", "GR", "HU", "IT", "NL", "NO", "PL", "PT", "SE"], // EU Schengen Area 1995 Open Border Agreement
  Bolivia: ["AR", "BR", "CL", "CO", "EC", "PE"], // MERCOSUR 2002/2009 Residence Agreement
  Brazil: ["AR", "BR", "CL", "CO", "EC", "PE"], // MERCOSUR 2002/2009 Residence Agreement
  Bulgaria: ["BG"],
  Canada: ["CA"],
  Chile: ["AR", "BR", "CL", "CO", "EC", "PE"], // MERCOSUR 2002/2009 Residence Agreement
  China: ["CN"],
  Colombia: ["AR", "BR", "CL", "CO", "EC", "PE"], // MERCOSUR 2002/2009 Residence Agreement
  Croatia: ["HR"],
  Cuba: ["CU"],
  Czechia: ["AT", "BE", "CH", "CZ", "DE", "DK", "ES", "FI", "FR", "GR", "HU", "IT", "NL", "NO", "PL", "PT", "SE"], // EU Schengen Area 1995 Open Border Agreement
  Denmark: ["AT", "BE", "CH", "CZ", "DE", "DK", "ES", "FI", "FR", "GR", "HU", "IT", "NL", "NO", "PL", "PT", "SE"], // EU Schengen Area 1995 Open Border Agreement
  'Dominican Republic': ["DO"],
  Ecuador: ["AR", "BR", "CL", "CO", "EC", "PE"], // MERCOSUR 2002/2009 Residence Agreement
  Estonia: ["AT", "BE", "CH", "CZ", "DE", "DK", "ES", "FI", "FR", "GR", "HU", "IT", "NL", "NO", "PL", "PT", "SE"], // EU Schengen Area 1995 Open Border Agreement
  Finland: ["AT", "BE", "CH", "CZ", "DE", "DK", "ES", "FI", "FR", "GR", "HU", "IT", "NL", "NO", "PL", "PT", "SE"], // EU Schengen Area 1995 Open Border Agreement
  France: ["AT", "BE", "CH", "CZ", "DE", "DK", "ES", "FI", "FR", "GR", "HU", "IT", "NL", "NO", "PL", "PT", "SE"], // EU Schengen Area 1995 Open Border Agreement
  Germany: ["AT", "BE", "CH", "CZ", "DE", "DK", "ES", "FI", "FR", "GR", "HU", "IT", "NL", "NO", "PL", "PT", "SE"], // EU Schengen Area 1995 Open Border Agreement
  Greece: ["AT", "BE", "CH", "CZ", "DE", "DK", "ES", "FI", "FR", "GR", "HU", "IT", "NL", "NO", "PL", "PT", "SE"], // EU Schengen Area 1995 Open Border Agreement
  Hungary: ["AT", "BE", "CH", "CZ", "DE", "DK", "ES", "FI", "FR", "GR", "HU", "IT", "NL", "NO", "PL", "PT", "SE"], // EU Schengen Area 1995 Open Border Agreement
  Iceland: ["AT", "BE", "CH", "CZ", "DE", "DK", "ES", "FI", "FR", "GR", "HU", "IT", "NL", "NO", "PL", "PT", "SE"], // EU Schengen Area 1995 Open Border Agreement
  Indonesia: ["ID"],
  Ireland: ["IE"],
  Italy: ["AT", "BE", "CH", "CZ", "DE", "DK", "ES", "FI", "FR", "GR", "HU", "IT", "NL", "NO", "PL", "PT", "SE"], // EU Schengen Area 1995 Open Border Agreement
  Japan: ["JP"],
  Kazakhstan: [],
  Latvia: ["AT", "BE", "CH", "CZ", "DE", "DK", "ES", "FI", "FR", "GR", "HU", "IT", "NL", "NO", "PL", "PT", "SE"], // EU Schengen Area 1995 Open Border Agreement
  Libya: ["LY"],
  Liechtenstein: ["AT", "CZ", "DE", "DK", "ES", "FI", "FR", "GR", "HU", "IT", "NL", "NO", "PL", "SE"], // EU Schengen Area 1995 Open Border Agreement
  Lithuania: ["AT", "CZ", "DE", "DK", "ES", "FI", "FR", "GR", "HU", "IT", "NL", "NO", "PL", "SE"], // EU Schengen Area 1995 Open Border Agreement
  Luxembourg: ["AT", "CZ", "DE", "DK", "ES", "FI", "FR", "GR", "HU", "IT", "NL", "NO", "PL", "SE"], // EU Schengen Area 1995 Open Border Agreement
  Malaysia: ["MY"],
  Malta: ["AT", "CZ", "DE", "DK", "ES", "FI", "FR", "GR", "HU", "IT", "NL", "NO", "PL", "SE"], // EU Schengen Area 1995 Open Border Agreement
  Mexico: ["MX"],
  Netherlands: ["AT", "CZ", "DE", "DK", "ES", "FI", "FR", "GR", "HU", "IT", "NL", "NO", "PL", "SE"], // EU Schengen Area 1995 Open Border Agreement
  'New Zealand': ["AU", "NZ"], // Trans-Tasman Travel Arrangement 1973
  Norway: ["AT", "CZ", "DE", "DK", "ES", "FI", "FR", "GR", "HU", "IT", "NL", "NO", "PL", "SE"], // EU Schengen Area 1995 Open Border Agreement
  Paraguay: ["AR", "BR", "CL", "CO", "EC", "PE"], // MERCOSUR 2002/2009 Residence Agreement
  Peru: ["AR", "BR", "CL", "CO", "EC", "PE"], // MERCOSUR 2002/2009 Residence Agreement
  Poland: ["AT", "CZ", "DE", "DK", "ES", "FI", "FR", "GR", "HU", "IT", "NL", "NO", "PL", "SE"], // EU Schengen Area 1995 Open Border Agreement
  Portugal: ["AT", "BE", "CH", "CZ", "DE", "DK", "ES", "FI", "FR", "GR", "HU", "IT", "NL", "NO", "PL", "PT", "SE"], // EU Schengen Area 1995 Open Border Agreement
  Romania: ["RO"],
  Russia: ["RU"],
  Serbia: ["RS"],
  Singapore: ["SG"],
  Slovakia: ["AT", "BE", "CH", "CZ", "DE", "DK", "ES", "FI", "FR", "GR", "HU", "IT", "NL", "NO", "PL", "PT", "SE"], // EU Schengen Area 1995 Open Border Agreement
  Slovenia: ["AT", "BE", "CH", "CZ", "DE", "DK", "ES", "FI", "FR", "GR", "HU", "IT", "NL", "NO", "PL", "PT", "SE"], // EU Schengen Area 1995 Open Border Agreement
  'South Africa': ["ZA"],
  'South Korea': ["KR"],
  Spain: ["AT", "BE", "CH", "CZ", "DE", "DK", "ES", "FI", "FR", "GR", "HU", "IT", "NL", "NO", "PL", "PT", "SE"], // EU Schengen Area 1995 Open Border Agreement
  Sweden: ["AT", "BE", "CH", "CZ", "DE", "DK", "ES", "FI", "FR", "GR", "HU", "IT", "NL", "NO", "PL", "PT", "SE"], // EU Schengen Area 1995 Open Border Agreement
  Switzerland: ["AT", "BE", "CH", "CZ", "DE", "DK", "ES", "FI", "FR", "GR", "HU", "IT", "NL", "NO", "PL", "PT", "SE"], // EU Schengen Area 1995 Open Border Agreement
  Taiwan: ["TW"],
  Thailand: ["TH"],
  Turkey: ["TR"],
  Turkmenistan: ["TM"],
  'United Kingdom': ["GB"],
  'United States/Puerto Rico': ["US", "PR"],
  Uruguay: ["UY"],
};

const iso2ToName = {
  AR: "Argentina",
  AT: "Austria",
  AU: "Australia",
  BE: "Belgium",
  BG: "Bulgaria",
  BR: "Brazil",
  BY: "Belarus",
  CA: "Canada",
  CH: "Switzerland",
  CL: "Chile",
  CN: "China",
  CO: "Colombia",
  CU: "Cuba",
  CZ: "Czechia",
  DE: "Germany",
  DK: "Denmark",
  DO: "Dominican Republic",
  EC: "Ecuador",
  ES: "Spain",
  FI: "Finland",
  FR: "France",
  GB: "United Kingdom",
  GR: "Greece",
  HR: "Croatia",
  HU: "Hungary",
  IE: "Ireland",
  ID: "Indonesia",
  IT: "Italy",
  JP: "Japan",
  KR: "South Korea",
  KZ: "Kazakhstan",
  LY: "Libya",
  MX: "Mexico",
  MY: "Malaysia",
  NL: "Netherlands",
  NO: "Norway",
  NZ: "New Zealand",
  PE: "Peru",
  PL: "Poland",
  PR: "Puerto Rico",
  PT: "Portugal",
  RO: "Romania",
  RS: "Serbia",
  RU: "Russia",
  SG: "Singapore",
  SE: "Sweden",
  TH: "Thailand",
  TM: "Turkmenistan",
  TR: "Turkey",
  TW: "Taiwan",
  US: "United States",
  UY: "Uruguay",
  ZA: "South Africa",
};

export { languageToCountries, passportToCountries, iso2ToName };
